import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Socials from '../components/Socials'


const Contact = () => ( <Layout>
  <div>
    <Helmet
      title={'Contact temtum | London, Leamington Spa, San Fransisco, Hong Kong'}
      meta={[
        {
          name: 'description',
          content: 'Contact temtum across any of our social or community channels. Based out of London, Leamington Spa, San Francisco and Hong Kong.'
        }
      ]}
    />
    <div className="hd-space"/>
    <div className="page">
      <section className="bg-blue-3 text-white">
        <video autoPlay="autoPlay" loop="loop" id="video-background" playsInline="playsinline" muted="muted">
          <source src="https://ak7.picdn.net/shutterstock/videos/22802857/preview/stock-footage-flying-above-huge-roundabout-bangkok-thailand.webm" type="video/webm"/>
        </video>
        <div className="darkest video-overlay"/>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="display-1 font-weight-normal pb-3">
                Contact Us
              </h1>

              <p className="leadX text-mutedX">
                Were happy to hear from supporters, partners, investors and if you simply have an interest in temtum and Temporal Blockchain.
              </p>
            </div>
          </div>
        </div>


      </section>

      <section className=" bg-blue-3 text-white">
        <div id="contact" className="mt-5X mb-5 pb-5">

          <div className="container">

            <div className="row contact-wrapper d-flex flex align-items-center">


              <div className="col-md-12">
                <div className="flexX">

                  <span className="d-block mb-3 text-blue text-uppercase">
                    Contact Us
                  </span>
                  <h2 className="display-3">Get in touch with temtum</h2>


                  <div className="row">
                    <div className="col-sm-6">
                      <div className="title-name mt-5 mb-3">General</div>
                      <a href="mailto:support@temtum.com" className="more-link mb-3">
                        support@temtum.com
                      </a>
                      <br/>
                    </div>

                    <div className="col-sm-6">
                      <div className="title-name mt-5 mb-3">Social</div>
                      <Socials/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</Layout> )

export default Contact;